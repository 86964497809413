import Vue from 'vue'


Vue.mixin({
    data() {
        return {
            strings: {
                "ja": {
                    "Secure Payment": "このお支払いは安全性が十分に確保されています",
                    "Complete Your Payment": "お支払ページ",
                    "Credit / Debit Card": "クレジット・デビットカード",
                    "Card Number": "カード番号",
                    "Full Name On Card": "カードに表示されているフルネーム",
                    "Expiration Date": "有効期限",
                    "Month": "月",
                    "Year": "年",
                    "CVV": "CVV",
                    "Submit": "お支払完了",
                    "Card Holder": "カード所有者",
                    "Full Name": "フルネーム",
                    "Expires": "有効期限",
                    "MM": "月",
                    "YY": "年",
                    "MM/YY": "月/年",
                    "Please input a valid card number": "カード番号をご入力ください",
                    "Please input valid card holder name": "カード所有者の名前をご入力ください",
                    "Please input a valid security code / cvv": "カードのセキュリティーコード（CVV番号）をご入力ください",
                    "Please input a valid expiration date": "カードの有効期限をご入力ください",
                },
                "zh": {
                    "Secure Payment": "安全支付",
                    "Complete Your Payment": "完成付款",
                    "Find Bank ...": "选择银行…",
                    "Online Bank Wire": "网上银行电汇",
                    "Select Your Bank Below": "在下面选择您的银行",
                    "No Bank Found For": "抱歉，没找到",
                    "Pay With": "使用...付款",
                    "See All Banks": "查看所有银行",
                    "Hengfeng Bank Co. Ltd": "恒丰银行",
                    "Hengfeng Bank Co.Ltd": "恒丰银行",
                    "Agricultural Bank of China": "中国农业银行",
                    "Shanghai Rural Commercial Bank": "上海农商银行",
                    "Industrial Bank Of Korea": "企业银行",
                    "Yunnan Rural Credit Cooperatives": "云南省农村信用社",
                    "Hainan Rural Credit Cooperatives": "海南省农村信用社",
                    "Guangxi Rural Credit Cooperatives(Cooperative Bank)": "广西农村信用社",
                    "The Press Association In Hubei Province Rural Credit Cooperatives": "湖北农信",
                    "Fujian Rural Credit Cooperatives": "福建省农村信用社",
                    "Jiangsu Province Rural Credit Union": "江苏省农村信用社联合社",
                    "Huishang Bank": "徽商银行",
                    "Chongqing Rural Commercial Bank": "重庆农村商业银行",
                    "Changshu Rural Commercial Bank": "常熟农村商业银行",
                    "Wujiang Rural Commercial Bank": "吴江农村商业银行",
                    "Bank Of Kunlun（Klb)": "昆仑银行",
                    "Bank Of Urumqi": "乌鲁木齐市商业银行",
                    "Bank Of Ningxia": "宁夏银行",
                    "Bank Of Qinghai": "青海银行",
                    "Bank Of Lanzhou Co.,Ltd.": "兰州银行股份有限公司",
                    "Fudian Bank Co.,Ltd": "富滇银行",
                    "Bank Of Guiyang": "贵阳银行",
                    "Mianyang City Commercial Bank": "绵阳市商业银行",
                    "Great Wall West China Bank Co.,Ltd.": "德阳银行",
                    "Panzhihua City Commercial Bank": "攀枝花市商业银行",
                    "Bank Of Chongqing Co.,Ltd.": "重庆银行股份有限公司",
                    "Bank Of Liuzhou": "柳州银行",
                    "Tianjin Rural Commercial Bank": "天津农商银行",
                    "Wuhan Rural Commercial Bank": "武汉农村商业银行",
                    "Bank Of Luoyang Co.,Ltd.": "洛阳银行",
                    "Bank Of Zhengzhou": "郑州银行",
                    "Bank Of Tianjin Co.,Ltd.": "天津银行",
                    "Zhuhai China Resources Bank Clearing Center": "珠海华润银行清算中心",
                    "Guangzhou Rural Commercial Bank; Grcb": "广州农村商业银行",
                    "Shunde Rural Commercial Bank": "顺德农村商业银行",
                    "Dezhou Bank": "德州银行",
                    "Bank Of Weifang": "潍坊银行",
                    "Bank Of Ganzhou": "赣州银行",
                    "Fujian Haixia Bank": "福建海峡银行",
                    "Zhejiang Mintai Commercial Bank": "浙江民泰商业银行",
                    "Bank Of Pingdingshan": "平顶山银行",
                    "Beijing Rural Commercial Bank": "北京农村商业银行",
                    "Taicang Rural Commercial Bank": "太仓农商行",
                    "Drc Bank": "东莞农村商业银行",
                    "Sichuan Rural Credit Cooperatives Union": "四川省联社",
                    "Shinhanfinancialgroup(China)": "新韩银行中国",
                    "Hana Bank": "韩亚银行",
                    "Bank Of Dalian": "大连银行",
                    "Anshan City Commercial Bank": "鞍山市商业银行",
                    "Bank Of Jinzhou": "锦州银行",
                    "Bank Of Huludao Co., Ltd": "葫芦岛银行",
                    "Bank Of Wenzhou": "温州银行",
                    "Bank Of Huzhou": "湖州银行",
                    "Zhejiang Chouzhou Commercial Bank": "浙江稠州商业银行",
                    "Zhejiang Tailong Commercial Bank": "浙江泰隆商业银行",
                    "Xiamen Bank": "厦门银行",
                    "Jiangxi Bank": "南昌银行",
                    "Bank Of Shangrao": "上饶银行",
                    "Bank Of Qingdao（Bqd）": "青岛银行",
                    "Qishang Bank": "齐商银行",
                    "Dongying Bank": "东营银行",
                    "Yantai Bank": "烟台银行",
                    "Bank Of Jining": "济宁银行",
                    "Bank Of Taian": "泰安市商业银行",
                    "Laishang Bank": "莱商银行",
                    "Weihai City Commercial Bank": "威海市商业银行",
                    "China Linshang Bank Co. Ltd.": "临商银行",
                    "Bank Of Rizhao": "日照银行",
                    "Bank Of Changsha": "长沙银行",
                    "Guangxi Beibu Gulf Bank": "广西北部湾银行",
                    "Zigong Commercial Bank Clearing Center": "自贡市商业银行清算中心",
                    "Kunshan Rural Commercial Bank": "昆山农村商业银行",
                    "Rural Commercial Bank Of Zhangjiagang": "张家港农村商业银行",
                    "Bank Of Suzhou": "苏州银行",
                    "Ningbo Yinzhou Rural Commercial Bank Co.,Ltd": "鄞州银行",
                    "Ahnsyh": "安徽省农村信用社联合社",
                    "Yellow River Rural Commercial Bank": "黄河农村商业银行",
                    "Bank Of Hebei Co., Ltd.": "河北银行股份有限公司",
                    "Bank Of Handan": "邯郸市商业银行",
                    "Bank Of Xingtai": "邢台银行",
                    "Zhangjiakou Commercial Bank Limited By Share Ltd": "张家口市商业银行",
                    "Bank Of Chegnde": "承德银行",
                    "Bank Of Cangzhou": "沧州银行",
                    "Jinshang Bank Online": "晋商银行网上银行",
                    "Jincheng Bank": "晋城银行",
                    "Bank Of Inner Mongolia": "内蒙古银行",
                    "Baoshang Bank Limited": "包商银行股份有限公司",
                    "Ordos Bank": "鄂尔多斯银行",
                    "Bank Of Yingkou": "营口银行",
                    "Fuxin Bank Clearing Center": "阜新银行结算中心",
                    "Bank Of Jilin": "吉林银行",
                    "Harbin Bank Clearing Center": "哈尔滨银行结算中心",
                    "Longjiang Bank Corporation": "龙江银行",
                    "Bank Of Nanjing": "南京银行",
                    "China Jiangsu Bank Co., Ltd.": "江苏银行股份有限公司",
                    "Bank Of Shaoxing": "绍兴银行",
                    "Bank Of Taizhou": "台州银行",
                    "China Guangdong Nanyue Bank Co., Ltd.": "广东南粤银行股份有限公司",
                    "Chang'an Bank": "长安银行",
                    "Jilin Province Rural Credit Bank": "吉林农村信用社",
                    "Shandong Rural Credit Union": "山东省农联社",
                    "Woori Bank": "友利银行",
                    "Jiaxing Bank Clearing Center": "嘉兴银行清算中心",
                    "Bank Of Langfang": "廊坊银行",
                    "Zhejiang Rural Credit Union": "浙江省农村信用社",
                    "China Guilin Bank Co., Ltd.": "桂林银行股份有限公司",
                    "Zhejiang Sanmen Yinzuo Village Bank": "浙江三门银座村镇银行",
                    "Qilu Bank": "齐鲁银行",
                    "Guangdong Huaxing Bank": "广东华兴银行",
                    "Beijing Shunyi Yinzuo Village Bank": "北京顺义银座村镇银行",
                    "Jiangxi Ganzhou Yinzuo Village Bank": "江西赣州银座村镇银行",
                    "Shenzhen Futian Yinzuo Village Bank": "深圳福田银座村镇银行",
                    "Chongqing Yubei Yinzuo Village Bank": "重庆渝北银座村镇银行",
                    "Chongqing Qianjiang Yinzuo Village Bank": "重庆黔江银座村镇银行",
                    "Zhejiang Jingning Yinzuo Village Bank": "浙江景宁银座村镇银行",
                    "Shenzhen Rural Commercial Bank": "深圳农商行",
                    "Bank Of Quanzhou Co., Ltd": "泉州银行",
                    "Jiangsu Changjiang Commercial Bank": "江苏长江商行",
                    "Bank Of Xi'an": "西安银行",
                    "Zaozhuang Bank": "枣庄银行",
                    "Haikou Rural Commercial Bank": "海口联合农村商业银行",
                    "Bank Of Jiujiang": "九江银行股份有限公司",
                    "Nanchong Commercial Ban": "南充市商业银行",
                    "Jiangyin Bank": "江阴农商银行",
                    "Bank Of East Asia": "东亚银行",
                    "Zhongyuan Bank Co.,Ltd": "中原银行",
                    "Hubei Bank Corporation Limited": "湖北银行",
                    "Webank": "深圳前海微众银行",
                    "Bank Of Hengshui": "衡水银行",
                    "Jiaozuo City Commercial Bank": "焦作市商业银行",
                    "Ningbo Commerce Bank": "宁波通商银行股份有限公司",
                    "Dongying Lai Commercial Village Bank": "东营莱商村镇银行股份有限公司",
                    "Rural Credit Cooperatives": "农村信用社",
                    "Bank Of Jinhua": "金华银行股份有限公司",
                    "Chengdu Rural Commercial Bank": "成都农村商业银行股份有限公司",
                    "Zhuhai Rural Credit Cooperatives": "珠海农村信用社",
                    "Hangzhou City Commercial Bank": "杭州市商业银行",
                    "Shunde Rural Credit Union": "顺德农村信用社",
                    "Yaodu Rural Credit Union": "尧都农村信用社",
                    "Jin Shang Commercial Bank": "晋商商业银行",
                    "Bank of Beijing": "北京银行",
                    "Bank of China": "中国银行",
                    "Bank of Dongguan": "东莞银行",
                    "Bank of Shanghai": "上海银行",
                    "China BoHai Bank": "渤海银行",
                    "China Construction Bank": "中国建设银行",
                    "China Everbright Bank": "光大银行",
                    "China Industrial Bank": "兴业银行",
                    "China Citic Bank": "中信银行",
                    "China Merchants Bank": "招商银行",
                    "China Minsheng Bank": "中国民生银行",
                    "Bank Of Communications": "交通银行",
                    "China Zheshang Bank": "浙商银行",
                    "Guandong Development Bank": "广发银行",
                    "Bank of Guangzhou": "广州银行",
                    "HuaXia Bank": "华夏银行",
                    "Bank Of HangZhou": "杭州银行",
                    "Industrial and Commercial Bank of China": "中国工商银行",
                    "Ping An Bank": "平安银行",
                    "Postal Savings Bank Of China": "中国邮政储蓄银行",
                    "Shenzhen Develop Bank": "深圳发展银行",
                    "Shanghai Pudong Development Bank": "浦发银行",
                    "HanKou Bank": "汉口银行",
                    "Bank of Ningbo": "宁波银行",
                    "Bank of Chengdu": "成都银行",
                }
            }
        }
    },
    methods: {

        // string translation method
        _(s, l) {
            if (!l) {
                l = window.mg_lang || "en";
            }
            if (!this.strings[l]) {
                return s;
            }
            let v = this.strings[l][s];
            if ([null, undefined].indexOf(v) !== -1) {
                return s;
            }
            return v;
        },

    }
});