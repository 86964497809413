<template>
  <div>
    <div v-if="bankCode">
      <div class="text-center pb-2">
        <img :alt="_(bankName)" :src="selectedBankPicUrl" style="height:80px;max-width:100%;" class="d-inline-block" />
      </div>
      <v-btn color="primary" class="pt-4 pb-8" block small @click="submit" :disabled="submitting" :loading="submitting">
        {{ $t('payWith') }} {{_(bankName)}}
      </v-btn>
      <v-btn block text class="mt-4 pt-4 pb-8 text--secondary" v-if="!submitting" small @click="bankCode = null">
        {{ $t('allBanks') }}
      </v-btn>
    </div>
    <div v-else>
      <v-subheader style="font-size:12px">{{ $t('selectBank') }}</v-subheader>

      <v-list-item dense key="search">
        <v-text-field
            v-model="bankSearch"
            type="text"
            :placeholder="$t('findBank')"
            class="caption my-0"
            dense
        ></v-text-field>
      </v-list-item>

      <div class="text-center">
        <v-alert v-if="(!filteredBanks || !filteredBanks.length) && bankSearch" type="info" class="caption" text>
          {{ $t('noBanks') }} '{{bankSearch}}'
        </v-alert>
        <v-flex v-for="b in filteredBanks" v-bind:key="b.code" class="bank-flex ma-2">
          <v-card
              width="120"
              height="130"
              class="mx-auto text-center ma-5 mb-2"
              @click="bankCode = b.code"
              outlined>
            <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
                <v-flex>
                  <img
                      style="max-width:100px;max-height:100px;"
                      class="ma-auto"
                      :alt="b.name"
                      :src="getImagePath(b.picUrl)" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
          <div class="text-center caption text--secondary" style="cursor:pointer" @click="bankCode = b.code"
          >{{_(b.name)}}</div>
        </v-flex>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "PayWithBankWire",
        props: {
            banks: Array,
            defaultCountry: String,
            selectCountry: String,
            order: String,
            token: String,
        },
        data() {
            return {
                submitting: false,
                bankCode: null,
                bankSearch: null,
            }
        },
        methods: {
            getImagePath(path) {
              return path.includes('https') ? path : require(`@/assets/banks/${path}`);
            },
            async submit() {

                if (!this.bankCode) {
                    alert("No bank selected");
                    return;
                }

                this.submitting = true;
                this.submitting = true;
                setTimeout(function(){
                    window.location.reload(true);
                }, 3000);
            },
        },
        computed: {
            filteredBanks() {
                if (!this.bankSearch) {
                    let banksToShow = []
                    for (let i = 0; i < this.banks.length; i++) {
                      if (this.selectCountry === "" || this.selectCountry === undefined) {
                        if (this.banks[i].country === this.defaultCountry) {
                          banksToShow.push(this.banks[i]);
                        }
                      } else {
                        if (this.banks[i].country === this.selectCountry) {
                          banksToShow.push(this.banks[i]);
                        }
                      }
                    }

                    return banksToShow;
                }
                let q = this.bankSearch.toString().toLowerCase();
                return this.banks.filter(b => b.name.toLowerCase().indexOf(q) !== -1 ||
                    this._(b.name).toLowerCase().indexOf(q) !== -1 ||
                    b.code.toLowerCase().indexOf(q) !== -1);
            },
            bankName() {
                if (!this.bankCode) {
                    return "";
                }
                let m = this.banks.filter(b => b.code === this.bankCode);
                if (!m || !m.length) {
                    return "";
                }
                return m[0].name;
            },
            selectedBankPicUrl() {
                if (!this.bankCode) {
                    return "";
                }
                let m = this.banks.filter(b => b.code === this.bankCode);
                if (!m || !m.length) {
                    return "";
                }
                return m[0].picUrl;
            },
        }
    }
</script>

<style scoped>
  .bank-flex{
    width:120px;
    display:inline-block;
    vertical-align: top;
    position: relative;
  }
</style>