import Vue from 'vue';
import numeral from 'numeral'

Vue.filter('commas', val => {
  if ([null,undefined].indexOf(val) !== -1) {
    return val;
  }
  if (val.toString().indexOf(".") === -1 && val.toString().length <= 2) {
    return val;
  }
  // let f = parseFloat(val);
  // if (Math.abs(f) < 1000) {
  //   return f.toFixed(2);
  // }
  return numeral(val).format('0,0.[00]');
});
