<template>
  <div>
    <div class="text-center pt-5">
      <img alt="QR" :src="imageUrl" style="max-width:100%;" class="qrimg d-inline-block" />
    </div>
  </div>
</template>

<script>
    export default {
        name: "PayWithQR",
        props: {
            imageUrl: String,
            order: String,
            token: String,
        },
        data() {
            return {
                submitting: false,
                bankCode: null,
                bankSearch: null,
            }
        },
        methods: {

        },
        computed: {

        }
    }
</script>

<style scoped>
  .qrimg{
    width:200px;
    max-width:200px;
    border:solid 1px #aaa;
    border-radius:8px;
  }
</style>