import 'core-js/stable'
import 'regenerator-runtime/runtime'
// import 'intersection-observer' // Optional
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import './assets/style.css'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask);

import './global'
import './filters/Commas'
import i18n from './i18n'

Vue.config.productionTip = false;


new Vue({
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
