<template>
  <div>
    <img v-if="imageUrl" :src="imageUrl" style="max-height:150px;max-width:380px;border:none !important;display:block;margin:0 auto;" class="d-block my-4" @click="submit"/>
    <v-btn color="primary" class="py-6" block small @click="submit" :disabled="submitting" :loading="submitting">
      {{redirectText ? redirectText : $t('continue') }}
    </v-btn>
  </div>
</template>

<script>

    export default {
        name: "PayWithRedirect",
        props: {
            order: String,
            token: String,
            catId: String,
            imageUrl: String,
            redirectText: String,
        },
        data() {
            return {
                submitting: false,
            }
        },
        methods: {
            async submit() {

                this.submitting = true;
                setTimeout(function(){
                  window.location.reload(true);
                }, 3000);
            },
        },
        computed: {}
    }
</script>

<style scoped>
  .bank-flex {
    width: 120px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
</style>