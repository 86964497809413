<template>
  <v-app>
    <div v-if="error" class="pa-3">{{error}}</div>
    <div v-else-if="loading" class="text-center">
      <v-text-field color="primary" class="ma-10" loading disabled></v-text-field>
    </div>
    <div id="overlay" v-else>
      <div class="mainbg"></div>
      <div id="menu" v-if="!isMobile">
          <div class="select-menu">
            <h4>Select Region</h4>
            <div class="menu-dropdown">
                <select v-model="selectedRegion" v-on:input="filterBanksByRegion($event.target.value)">
                    <option v-for="region in regions" v-bind:key="region.key" :value="region.name">{{region.name}}</option>
                </select>
            </div>
          </div>
          <div class="select-menu">
            <h4>Select Country</h4>
            <div class="menu-dropdown">
                <select v-model="selectedCountry" v-on:input="filterBanksByCountry($event.target.value)">
                    <option v-for="country in countries" v-bind:key="country.key" :value="country.name">{{country.name}}</option>
                </select>
            </div>
          </div>
          <div id="select-categories">
              <form>
                <h3> International Categories </h3>
                <div class="category">
                    <input type="checkbox"
                        value="card"
                        id="cc"
                        v-model="internationalCategoresChecked"
                        @change="showHideCategory()">
                    <label for="cc"> Credit / Debit Card</label>
                </div>
                <div class="category">
                    <input type="checkbox"
                        value="crypto"
                        id="crypto"
                        v-model="internationalCategoresChecked"
                        @change="showHideCategory()">
                    <label for="crypto"> Crypto Currency</label>
                </div>
                <div class="category">
                    <input type="checkbox"
                        value="unionPay"
                        id="unionPay"
                        v-model="internationalCategoresChecked"
                        @change="showHideCategory()">
                    <label for="unionPay"> UnionPay International</label>
                </div>
              </form>
          </div>
          <div id="reset-filters">
              <button v-on:click="resetFilters()">Restart Demo</button>
          </div>
      </div>
      <div id="mobile-menu" v-else>
            <v-app-bar id="mobile-app-bar">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <v-btn icon class="lang-choice" id="main-lang" v-if="countryLang && countryLang != 'en'" @click="changeLanguage(countryLang)">
                    <country-flag :country="countryLang" size='normal'/>
                </v-btn>
                <v-btn icon class="lang-choice" id="main-lang" @click="changeLanguage('en')">
                    <country-flag country='gb' size='normal'/>
                </v-btn>
            </v-app-bar>
            <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
            <v-select
            v-model="selectedRegion"
            v-on:input="filterBanksByRegion($event)"
            :items="regions"
            item-text="name"
            item-value="name"
            label="Select Region"
            dense
            >

            </v-select>

            <v-select
            v-model="selectedCountry"
            v-on:input="filterBanksByCountry($event)"
            :items="countries"
            item-text="name"
            item-value="name"
            label="Select Country"
            dense
            >

            </v-select>

            <v-checkbox
            v-model="internationalCategoresChecked"
            label="Credit / Debit Card"
            value="card"
            @change="showHideCategory()"
            dense></v-checkbox>

            <v-checkbox
            v-model="internationalCategoresChecked"
            label="Crypto Currency"
            value="crypto"
            @change="showHideCategory()"
            dense></v-checkbox>

            <v-checkbox
            v-model="internationalCategoresChecked"
            label="UnionPay International"
            value="unionPay"
            @change="showHideCategory()"
            dense></v-checkbox>
        </v-list-item-group>
      </v-list>

        <div class="pa-2">
          <v-btn block @click="resetFilters()">
            Reset
          </v-btn>
        </div>
    </v-navigation-drawer>
      </div>
      <div id="lang-menu" v-if="!isMobile">
          <div class="lang-choice" id="main-lang" @click="changeLanguage('en')">
              <country-flag country='gb' size='normal'/>
          </div>
          <div class="lang-choice" id="country-lang">
              <div v-if="countryLang && countryLang != 'en'" @click="changeLanguage(countryLang)">
                <country-flag :country="countryLang" size='normal'/>
              </div>
          </div>
      </div>
      <div id="box">
        <div id="main">
          <div class="header">
            <div class="logo">
              <div class="logo-wrap">
                <div class="logo-bevel"></div>
                <div class="logo-border"></div>
                <div class="logo-img"
                     :style="`background:#fff url('${logoUrl}') no-repeat center;background-size:65px 65px;`">
                </div>
              </div>
            </div>
            <h1>{{ $t('heading') }}</h1>
            <h2>
              {{orderCurrencySymbol}}
              {{orderAmount | commas}}
              {{orderCurrency}}
            </h2>
          </div>
          <div class="sep">
            <div class="inner"></div>
          </div>
          <div class="section">
            <div class="content">
              <v-subheader style="font-size:12px" v-if="methodTypes.length > 1">{{ $t('selectPaymentMethod') }}</v-subheader>
              <v-expansion-panels popout hover v-model="panelIdx" :readonly="methodTypes.length < 2">
                <v-expansion-panel
                    v-for="(item, i) in methodTypes"
                    :key="i"
                >
                  <v-expansion-panel-header disable-icon-rotate style="font-size:13px"
                                            :class="{'font-weight-bold': panelIdx === i}">
                    {{item.name}}
                    <template v-slot:actions>
                      <v-icon small :color="panelIdx === i ? 'primary' : 'grey lighten-1'">{{item.icon}}</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pay-with-card
                        v-if="item.type === 'CARD' && panelIdx !== null"
                        :order="orderID"
                        :token="token"/>
                    <pay-with-bank-wire
                        v-if="item.type === 'BANKS' && panelIdx !== null"
                        :banks="item.banks"
                        :defaultCountry="item.defaultCountry"
                        :selectCountry="item.selectCountry"
                        :order="orderID"
                        :token="token"/>
                    <pay-with-q-r
                        v-if="item.type === 'QR' && panelIdx !== null"
                        :image-url="qrImageUrl"
                        :order="orderID"
                        :token="token"/>
                    <pay-with-q-r
                        v-if="item.type === 'BARCODE' && panelIdx !== null"
                        :image-url="barcodeImageUrl"
                        :order="orderID"
                        :token="token"/>
                    <pay-with-redirect
                        v-if="item.type === 'REDIRECT' && panelIdx !== null"
                        :image-url="item.imageUrl"
                        :redirect-text="item.redirectText"
                        :order="orderID"
                        :token="token"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div class="sep mt-8">
            <div class="inner"></div>
          </div>
          <div class="pt-5 px-5 text-center">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <img v-on="on" src="~@/assets/ssl_certified.png" alt="SSL Certified" height="35" class="px-2"/>
              </template>
              <span>SSL Secure Payment</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <img v-on="on" src="~@/assets/pci_compliant.png" alt="PCI DSS Compliant" height="35" class="px-2"/>
              </template>
              <span>PCI DSS Compliant</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
    import CountryFlag from 'vue-country-flag';

    import PayWithCard from "./PayWithCard";
    import PayWithBankWire from "./PayWithBankWire";
    import PayWithQR from "./PayWithQR";
    import PayWithRedirect from "./PayWithRedirect";

    import banksList from "./assets/banks.json";

    function getDefaultData() {
        return {
                    loading: true,
                    submitting: false,
                    logoUrl: "https://account.zotapay.com/img/zp-logo.f0d6d471.png",
                    error: null,
                    orderID: null,
                    token: null,
                    orderAmount: null,
                    orderCurrency: null,
                    orderCurrencySymbol: null,
                    panelIdx: null,
                    isMobile: false,
                    drawer: false,
                    group: null,
                    paymentOptions: null,
                    // banks: [],
                    // banksList: null,
                    qrImageUrl: null,
                    barcodeImageUrl: null,
                    language: "en",
                    countryLang: null,
                    returnUrl: null,
                    regions: [
                        {key: 1, name: "SEA"},
                        {key: 2, name: "LATAM"},
                        {key: 3, name: "China"},
                        {key: 4, name: "India"},
                        {key: 5, name: "Africa"}
                    ],
                    selectedRegion: "",
                    selectedCountry: "",
                    internationalCategoresChecked: ["card", "crypto", "unionPay"]
                }
    }

    export default {
        name: 'app',
        components: {CountryFlag, PayWithQR, PayWithBankWire, PayWithCard, PayWithRedirect},
        data: getDefaultData,
        watch: {
            group() {
                this.drawer = false
            },
        },
        computed: {
            countries () {
                let countries = [
                    {key: 1, name: "Malaysia", region: "SEA"},
                    {key: 2, name: "Indonesia", region: "SEA"},
                    {key: 3, name: "Vietnam", region: "SEA"},
                    {key: 4, name: "Thailand", region: "SEA"},
                    {key: 5, name: "India", region: "India"},
                    {key: 6, name: "South Africa", region: "Africa"},
                    {key: 7, name: "Brazil", region: "LATAM"},
                    {key: 8, name: "Chile", region: "LATAM"},
                    {key: 9, name: "Mexico", region: "LATAM"},
                    {key: 10, name: "Peru", region: "LATAM"},
                    {key: 11, name: "Ecuador", region: "LATAM"},
                    {key: 12, name: "Colombia", region: "LATAM"},
                    {key: 13, name: "Argentina", region: "LATAM"},
                    {key: 14, name: "Guatemala", region: "LATAM"},
                    {key: 15, name: "El Salvador", region: "LATAM"},
                    {key: 16, name: "Panama", region: "LATAM"},
                    {key: 17, name: "China", region: "China"},
                ]
                return this.selectedRegion === "" ? countries : countries.filter(country => country.region === this.selectedRegion)
            },
            methodTypes() {
                let ret = [];
                if (this.paymentOptions == null) {
                    return ret;
                }
                if (this.paymentOptions.bankWires) {
                    ret.push({
                        id: "bankWire",
                        name: this.$i18n.t('paymentMethods.bankWire'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.onlineBankWireBanks,
                        defaultCountry: "Indonesia",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.onlinePayment) {
                    ret.push({
                        id: "onlinePayment",
                        name: this.$i18n.t('paymentMethods.online'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.onlinePaymentBanks,
                        defaultCountry: "Brazil",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.qrCode) {
                    ret.push({
                        id: "qrCode",
                        name: this.$i18n.t('paymentMethods.qrCode'),
                        icon: "mdi-qrcode-scan",
                        type: "QR"
                    });
                }
                if (this.paymentOptions.barcode) {
                    ret.push({
                        id: "barcode",
                        name: this.$i18n.t('paymentMethods.barcode'),
                        icon: "mdi-qrcode-scan",
                        type: "BARCODE"
                    });
                }
                if (this.paymentOptions.eWallet) {
                    ret.push({
                        id: "eWallet",
                        name: this.$i18n.t('paymentMethods.eWallet'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.eWalletBanks,
                        defaultCountry: "Indonesia",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.atmOnline) {
                    ret.push({
                        id: "atmOnline",
                        name: this.$i18n.t('paymentMethods.atmOnline'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.atmOnlineBanks,
                        defaultCountry: "Vietnam",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.offlineBankWire) {
                    ret.push({
                        id: "offlineBankWire",
                        name: this.$i18n.t('paymentMethods.offlineBankWire'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.offlineBankWireBanks,
                        defaultCountry: "Vietnam",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.cashPayment) {
                    ret.push({
                        id: "cashPayment",
                        name: this.$i18n.t('paymentMethods.cash'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.cashPaymentBanks,
                        defaultCountry: "Brazil",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.atmOffline) {
                    ret.push({
                        id: "atmOffline",
                        name: this.$i18n.t('paymentMethods.atmOffline'),
                        icon: "mdi-server-security",
                        type: "BANKS",
                        banks: banksList.atmOfflineBanks,
                        defaultCountry: "Vietnam",
                        selectCountry: this.selectedCountry
                    });
                }
                if (this.paymentOptions.p2pPayment) {
                    ret.push({
                        id: "p2pPayment",
                        name: this.$i18n.t('paymentMethods.p2p'),
                        icon: "mdi-server-security",
                        type: "REDIRECT",
                        imageUrl: require(`@/assets/p2p_payment.svg`)
                    });
                }
                if (this.paymentOptions.cardPayments) {
                    ret.push({
                        id: "card",
                        name: this.$i18n.t('paymentMethods.card'),
                        icon: "mdi-credit-card-outline",
                        type: "CARD"
                    });
                }
                if (this.paymentOptions.cryptoCurrency) {
                    ret.push({
                        id: "cryptoCurrency",
                        name: this.$i18n.t('paymentMethods.crypto'),
                        icon: "mdi-server-security",
                        type: "REDIRECT",
                        imageUrl: require(`@/assets/crypto_currency.png`)
                    });
                }
                if (this.paymentOptions.unionPay) {
                    ret.push({
                        id: "unionPay",
                        name: this.$i18n.t('paymentMethods.unionPay'),
                        icon: "mdi-server-security",
                        type: "REDIRECT",
                        imageUrl: require(`@/assets/union_pay.png`)
                    });
                }
                return ret;
            },
        },
        methods: {
            setListener() {
                window.addEventListener('resize', this.calculateWidth);
            },
            calculateWidth(){
                if (window.innerWidth < 1024) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            },
            changeLanguage(lang) {
                this.$i18n.locale = lang;
            },
            filterBanksByCountry: function(country) {
                this.selectedCountry = country;
                if (country == "Malaysia") {
                    this.selectedRegion = "SEA";
                    this.hideAllPaymentOptions();
                    this.paymentOptions.bankWires = true;

                    // local amount and currency
                    this.orderAmount = 400.60;
                    this.orderCurrency = "MYR";
                    this.orderCurrencySymbol = "RM";

                    // change to local language
                    this.$i18n.locale = 'my';
                    this.countryLang = "my";

                }
                if (country == "Indonesia") {
                    this.selectedRegion = "SEA";
                    this.hideAllPaymentOptions();
                    this.paymentOptions.bankWires = true;
                    this.paymentOptions.eWallet = true;
                    this.paymentOptions.offlineBankWire = true;

                    // local amount and currency
                    this.orderAmount = "1,389,037.00";
                    this.orderCurrency = "IDR";
                    this.orderCurrencySymbol = "Rp";

                    // change to local language
                    this.$i18n.locale = 'id';
                    this.countryLang = "id";
                }
                if (country == "Vietnam") {
                    this.selectedRegion = "SEA";
                    this.paymentOptions.bankWires = true;
                    this.paymentOptions.onlinePayment = false;
                    this.paymentOptions.qrCode = false;
                    this.paymentOptions.eWallet = true;
                    this.paymentOptions.atmOnline = true;
                    this.paymentOptions.offlineBankWire = true;
                    this.paymentOptions.cashPayment = true;
                    this.paymentOptions.atmOffline = true;
                    this.paymentOptions.p2pPayment = false;
                    this.paymentOptions.cardPayments = false;
                    this.paymentOptions.cryptoCurrency = false;
                    this.paymentOptions.unionPay = false;
                    this.paymentOptions.barcode = false;

                    // local amount and currency
                    this.orderAmount = "2,317,123.00";
                    this.orderCurrency = "VND";
                    this.orderCurrencySymbol = "₫";

                    // change to local language
                    this.$i18n.locale = 'vn';
                    this.countryLang = "vn";
                }
                if (country == "Thailand") {
                    this.selectedRegion = "SEA";
                    this.hideAllPaymentOptions();
                    this.paymentOptions.bankWires = true;
                    this.paymentOptions.qrCode = true;

                    // local amount and currency
                    this.orderAmount = "2,987.77";
                    this.orderCurrency = "THB";
                    this.orderCurrencySymbol = "฿";

                    // change to local language
                    this.$i18n.locale = 'th';
                    this.countryLang = "th";
                }
                if (country == "Brazil" || country == "Chile" || country == "Mexico" || country == "Peru" || 
                    country == "Ecuador" || country == "Colombia" || country == "Argentina" || country == "Guatemala") {
                    this.selectedRegion = "LATAM";
                    this.hideAllPaymentOptions();
                    this.paymentOptions.onlinePayment = true;
                    this.paymentOptions.cashPayment = true;

                    // local amount and currency
                    this.orderAmount = 100;
                    this.orderCurrency = "USD";
                    this.orderCurrencySymbol = "$";

                    // change to default language
                    this.$i18n.locale = 'en';
                    this.countryLang = null;
                }
                if (country == "El Salvador" || country == "Panama") {
                    this.selectedRegion = "LATAM";
                    this.hideAllPaymentOptions();
                    this.paymentOptions.cashPayment = true;

                    // local amount and currency
                    this.orderAmount = 100;
                    this.orderCurrency = "USD";
                    this.orderCurrencySymbol = "$";

                    // change to default language
                    this.$i18n.locale = 'en';
                    this.countryLang = null;
                }
                if (country === "China") {
                    this.selectedRegion = "China";
                    this.hideAllPaymentOptions();
                    this.paymentOptions.p2pPayment = true;
                    this.paymentOptions.unionPay = true;
                    this.internationalCategoresChecked = ["unionPay"];

                    // local amount and currency
                    this.orderAmount = 646.32;
                    this.orderCurrency = "CNY";
                    this.orderCurrencySymbol = "¥";

                    // change to local language
                    this.$i18n.locale = 'cn';
                    this.countryLang = "cn";
                }
                if (country === "India" || country === "South Africa") {
                    if (country == "India") {
                        this.selectedRegion = "India";

                        // local amount and currency
                        this.orderAmount = "7,303.00";
                        this.orderCurrency = "INR";
                        this.orderCurrencySymbol = "₹";
                    } else {
                        this.selectedRegion = "Africa"

                        // local amount and currency
                        this.orderAmount = "1,457.02";
                        this.orderCurrency = "ZAR";
                        this.orderCurrencySymbol = "R";
                    }
                    this.hideAllPaymentOptions();
                    this.paymentOptions.bankWires = true;
                    this.paymentOptions.cardPayments = true;
                    this.internationalCategoresChecked = ["card"];

                    // change to default language
                    this.$i18n.locale = 'en';
                    this.countryLang = null;
                }

                if (this.methodTypes.length == 1) {
                    this.panelIdx = 0;
                }
            },
            filterBanksByRegion: function(region) {
                this.selectedRegion = region;
                this.selectedCountry = "";

                if (this.selectedRegion === "SEA") {
                    this.filterBanksByCountry("Thailand")
                }
                if (this.selectedRegion === "LATAM") {
                    this.filterBanksByCountry("Brazil")
                }
                if (this.selectedRegion === "China") {
                    this.filterBanksByCountry("China")
                }
                if (this.selectedRegion === "India") {
                    this.filterBanksByCountry("India")
                }
                if (this.selectedRegion === "Africa") {
                    this.filterBanksByCountry("South Africa")
                }
            },
            showHideCategory: function() {
                console.log(this.internationalCategoresChecked);
                if (this.internationalCategoresChecked.includes("card")) {
                    this.paymentOptions.cardPayments = true;
                } else {
                    this.paymentOptions.cardPayments = false;
                }
                if (this.internationalCategoresChecked.includes("crypto")) {
                    this.paymentOptions.cryptoCurrency = true;
                } else {
                    this.paymentOptions.cryptoCurrency = false;
                }
                if (this.internationalCategoresChecked.includes("unionPay")) {
                    this.paymentOptions.unionPay = true;
                } else {
                    this.paymentOptions.unionPay = false;
                }

                if (this.methodTypes.length == 1) {
                    this.panelIdx = 0;
                }
            },
            hideAllPaymentOptions() {
                this.paymentOptions.bankWires = false;
                this.paymentOptions.onlinePayment = false;
                this.paymentOptions.qrCode = false;
                this.paymentOptions.eWallet = false;
                this.paymentOptions.atmOnline = false;
                this.paymentOptions.offlineBankWire = false;
                this.paymentOptions.cashPayment = false;
                this.paymentOptions.atmOffline = false;
                this.paymentOptions.p2pPayment = false;
                this.paymentOptions.cardPayments = false;
                this.paymentOptions.cryptoCurrency = false;
                this.paymentOptions.unionPay = false;
                this.paymentOptions.barcode = false;
                this.internationalCategoresChecked = [];
            },
            getQueryParam(name, url) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            },
            randomNum(min, max) {
                return Math.floor(Math.random() * max) + min;
            },

            resetFilters() {
                this.selectedRegion = "";
                this.selectedCountry = "";
                this.internationalCategoresChecked = ["card", "crypto", "unionPay"];
                this.countryLang = null;
                this.load();
            },

            async load() {
                this.loading = true;

                let qLang = this.getQueryParam("lang");
                window.mg_lang = qLang ? qLang : "en";
                this.changeLanguage("en");

                try {
                    this.orderAmount = 100;
                    this.orderCurrency = "USD";
                    this.orderCurrencySymbol = "$";
                    this.returnUrl = window.location.href;
                    this.qrImageUrl = require(`@/assets/zotapay_contact.png`);
                    this.barcodeImageUrl = require(`@/assets/barcode.png`);
                    this.paymentOptions = {
                        "bankWires": true,
                        "onlinePayment": true,
                        "qrCode": true,
                        "eWallet": true,
                        "atmOnline": true,
                        "offlineBankWire": true,
                        "cashPayment": true,
                        "atmOffline": true,
                        "p2pPayment": true,
                        "cardPayments": true,
                        "cryptoCurrency": true,
                        "barcode": true,
                        "unionPay": true,
                        "others": false,
                        "paymentMethods": ["INSTANT-BANK-WIRE", "MASTERCARD", "JCB", "QRCODE"]
                    };

                } catch (err) {
                    console.log(err);
                    this.error = "not found";
                } finally {
                    this.loading = false;
                    document.title = this.$i18n.t('title');
                }
            },
        },
        async mounted() {
            await this.load();
            this.setListener();
            this.calculateWidth();
            if (this.paymentOptions) {
                if (this.paymentOptions.cardPayments && !this.paymentOptions.bankWires) {
                    this.panelIdx = 0;
                } else if (!this.paymentOptions.cardPayments && this.paymentOptions.bankWires) {
                    this.panelIdx = 0;
                }
            }
        },
    }
</script>